import { getImage } from 'gatsby-plugin-image'

import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import CallToAction from '~/components/call-to-actions/CallToAction'
import Service3DIconCard from '~/components/cards/Service3DIconCard'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import DiscoverWorksTabs from '~/components/items/DiscoverWorksTabs'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import LazyHydrate from '~/components/shared/LazyHydrate'
import SubtractBlock from '~/components/shared/SubtractBlock'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/custom-enterprise-software-development/Anchors'
import CustomEnterpriseSoftwareDevBenefits from '~/mock/services/custom-enterprise-software-development/CustomEnterpriseSoftwareDevBenefits'
import CustomEnterpriseSoftwareDevExperience from '~/mock/services/custom-enterprise-software-development/CustomEnterpriseSoftwareDevExperience'
import CustomEnterpriseSoftwareDevExpertise from '~/mock/services/custom-enterprise-software-development/CustomEnterpriseSoftwareDevExpertise'
import CustomEnterpriseSoftwareDevFAQ from '~/mock/services/custom-enterprise-software-development/CustomEnterpriseSoftwareDevFAQ'
import CustomEnterpriseSoftwareDevIndustries from '~/mock/services/custom-enterprise-software-development/CustomEnterpriseSoftwareDevIndustries'
import CustomEnterpriseSoftwareDevPractices from '~/mock/services/custom-enterprise-software-development/CustomEnterpriseSoftwareDevPractices'
import CustomEnterpriseSoftwareDevProcess from '~/mock/services/custom-enterprise-software-development/CustomEnterpriseSoftwareDevProcess'
import CustomEnterpriseSoftwareDevServices from '~/mock/services/custom-enterprise-software-development/CustomEnterpriseSoftwareDevServices'
import CustomEnterpriseSoftwareDevSolutions from '~/mock/services/custom-enterprise-software-development/CustomEnterpriseSoftwareDevSolutions'
import CustomEnterpriseSoftwareDevTabContent from '~/mock/services/custom-enterprise-software-development/CustomEnterpriseSoftwareDevTabContent'
import CustomEnterpriseSoftwareDevTabList from '~/mock/services/custom-enterprise-software-development/CustomEnterpriseSoftwareDevTabList'
import { fileToImageLikeData } from '~/utils'
import CompanyProjectsLogoSection from '~/views/Homepage/components/CompanyProjectsLogoSection'
import ServicesDevExpertiseCompany from '~/views/Services/components/ServicesDevExpertiseCompany'
import ServicesProcess from '~/views/Services/components/ServicesProcess'
import TableCostCustomEnterpriseSoftwareDev from '~/views/Services/components/ServicesTables/TableCostCustomEnterpriseSoftwareDev'
import ServicesTechStackSection from '~/views/Services/components/ServicesTechStackSection'
import useCustomEnterpriseSoftwareDevelopmentStaticQuery from '~/views/Services/CustomEnterpriseSoftwareDevelopment/useCustomEnterpriseSoftwareDevelopmentStaticQuery'

import * as containerStyles from './CustomEnterpriseSoftwareDevelopment.module.scss'

const CustomEnterpriseSoftwareDevelopment = () => {
  const query = useCustomEnterpriseSoftwareDevelopmentStaticQuery()
  const clientPMK = getImage(fileToImageLikeData(query.clientPMK))
  const customEnterpriseSoftwareDevTabContent =
    CustomEnterpriseSoftwareDevTabContent()

  return (
    <MainLayout scrollTop>
      <section className={containerStyles.customEnterpriseDevPrimeSection}>
        <PrimeSectionServices
          titleTop="Custom Enterprise Software"
          title="Development Services"
          description="Technology rules this world. At the Codica web app development company, we provide high-quality custom enterprise software that helps your business grow and thrive."
          breadcrumbSecondLevel="Services"
          breadcrumbSecondLevelPath="/services/"
          breadcrumbCurrent="Custom Enterprise Software Development Services"
          breadcrumbCurrentPath="/services/custom-enterprise-software-development/"
        />
      </section>

      <StickyNavigation anchors={Anchors} />

      <section
        id="services"
        className={containerStyles.customEnterpriseDevServicesSection}
      >
        <HeadSection
          position="center"
          title="Custom enterprise software development services we offer"
          description="Obtain a full spectrum of custom enterprise software development services by us to become a winner in the market."
          titleLarge
          descriptionLarge
        />
        <Service3DIconCard dataContent={CustomEnterpriseSoftwareDevServices} />
      </section>

      <section
        id="industries"
        className={containerStyles.customEnterpriseDevIndustriesSection}
      >
        <HeadSection
          position="center"
          title="Various industries we cover"
          description="For more than eight years, our expert team has been helping customers from various industries create top products and reduce custom enterprise software development time and costs."
          descriptionLarge
        />
        <ServicesIndustriesCard
          dataContent={CustomEnterpriseSoftwareDevIndustries}
        />
      </section>

      <div className={containerStyles.customEnterpriseDevCallToActionSection}>
        <CallToAction
          title="Want to elevate your business with customized software solutions?"
          subtitle="Join forces with our team of experts."
          classNameForGA="servicesCTA_1"
          linkName="Contact us"
          link="/contacts/"
        />
      </div>

      <section
        id="solutions"
        className={containerStyles.customEnterpriseDevSolutionSection}
      >
        <HeadSection
          position="center"
          title="Common custom enterprise software development solutions"
          description="We create reliable and scalable software that automates clients’ business processes. Our specialists develop enterprise products that include software for data storage and document management systems, and business solutions unique to each business."
          titleLarge
          descriptionLarge
        />
        <ServicesIconSmallCard
          isLeft
          dataContent={CustomEnterpriseSoftwareDevSolutions}
        />
      </section>

      <section
        id="process"
        className={containerStyles.customEnterpriseDevProcessSection}
      >
        <HeadSection
          position="center"
          title="Crafting your vision: custom enterprise software development process"
          description="Our comprehensive guide integrates five crucial stages, ensuring an exceptional end-to-end experience for every project."
          titleLarge
          descriptionLarge
        />
        <ServicesProcess dataContent={CustomEnterpriseSoftwareDevProcess} />
      </section>

      <section
        id="tech-stack"
        className={containerStyles.customEnterpriseDevTechSection}
      >
        <HeadSection
          position="center"
          title="Cutting-edge tech stack for custom enterprise software development"
          description="Depending on the project’s requirements, Codica’s development team uses different programming languages, frameworks, libraries, and other tools. We keep up with technological evolution to explore new technologies and introduce innovations to our customers’ projects."
          titleLarge
          descriptionLarge
        />
        <ServicesTechStackSection />
      </section>

      <section
        id="benefits"
        className={containerStyles.customEnterpriseDevBenefitsSection}
      >
        <HeadSection
          position="center"
          title="Get business growth with the benefits of enterprise software development"
          description="It is vital to discover the boundless advantages of embracing enterprise custom software development services to unlock a world of possibilities for your business idea."
          titleLarge
          descriptionLarge
        />
        <ServicesIconSmallCard
          dataContent={CustomEnterpriseSoftwareDevBenefits}
        />
      </section>

      <CallToAction
        title="Looking for a reliable software development partner?"
        subtitle="Let’s create software products together."
        classNameForGA="servicesCTA_2"
        linkName="Talk to an expert"
        link="/contacts/"
      />

      <section
        id="costs"
        className={containerStyles.customEnterpriseDevCostSection}
      >
        <HeadSection
          position="center"
          title="Understanding costs of custom enterprise software development"
          description="We aim to empower you with a clear insight into the prices associated with custom enterprise software development, ensuring you have the knowledge and confidence to make informed decisions. Our team works closely with you to define your budget, prioritize features, and optimize resources."
          titleLarge
          descriptionLarge
        />
        <TableCostCustomEnterpriseSoftwareDev />
      </section>

      <section
        id="practices"
        className={containerStyles.customEnterpriseDevPracticesSection}
      >
        <HeadSection
          position="center"
          title="Best practices for custom enterprise software development at Codica"
          description="We adhere to best practices for custom enterprise software development that guarantee exceptional client outcomes. With Codica, you can expect a tailored solution that seamlessly integrates into your business, empowers your users, and positions you for long-term success in the digital landscape."
          titleLarge
          descriptionLarge
        />
        <ServicesIconSmallCard
          isColMd
          dataContent={CustomEnterpriseSoftwareDevPractices}
        />
      </section>

      <LazyHydrate whenVisible>
        <section className={containerStyles.customEnterpriseDevProjLogoSection}>
          <CompanyProjectsLogoSection isCenter />
        </section>
      </LazyHydrate>

      <section className={containerStyles.customEnterpriseDevExpertiseSection}>
        <HeadSection
          position="center"
          title="Choose excellence: why Codica is your partner for custom enterprise software development"
          description="Our unwavering commitment to excellence, extensive expertise, and client-centric approach sets us apart in the industry. When you choose Codica as a custom enterprise software development company, you can expect a seamless and collaborative journey from concept to implementation. Our great experience in software development empowers us to craft tailored solutions that perfectly align with your business requirements."
          titleLarge
          descriptionLarge
        />
        <ServicesDevExpertiseCompany
          dataContent={CustomEnterpriseSoftwareDevExpertise}
        />
      </section>

      <section className={containerStyles.customEnterpriseDevExperienceSection}>
        <HeadSection
          position="center"
          title="8 years of Codica’s extensive experience"
          description="With a wealth of experience and a passion for crafting innovative solutions, Codica stands at the forefront of the industry. From empowering businesses with custom solutions to ensuring an unparalleled user experience, Codica’s expertise is unrivaled."
          descriptionLarge
        />
        <ServicesIconSmallCard
          dataContent={CustomEnterpriseSoftwareDevExperience}
        />
        <SubtractBlock />
      </section>

      <section
        id="cases"
        className={containerStyles.customEnterpriseDevCaseSection}
      >
        <HeadSection position="left" title="Our exciting case studies" />
        <DiscoverWorksTabs
          dataTabList={CustomEnterpriseSoftwareDevTabList}
          dataContent={customEnterpriseSoftwareDevTabContent}
        />
      </section>

      <section className={containerStyles.customEnterpriseDevQuotesSection}>
        <HeadSection
          position="center"
          title="Client testimonials"
          description="At Codica, we take immense pride in delivering exceptional custom enterprise software solutions that drive business growth and transform our clients’ operations. Here we present a collection of feedback from our satisfied clients who have entrusted us with their enterprise software needs."
          descriptionLarge
        />
        <div className="container container-md">
          <ClientVideoQuotes
            name="David Watkins"
            location="USA"
            position="Founder of PlanMyKids"
            image={clientPMK}
            quote={[
              {
                text: '“The PlanMyKids journey started when my family struggled to plan our own kid’s activities. As busy parents, we were time-constrained, the information we needed was all over the place, and coordinating with family felt like it required a project management certification.”',
              },
            ]}
            link="kids-activities-service-marketplace"
            videoLink="https://www.youtube.com/embed/dzxMCDLghZQ"
          />
        </div>
      </section>

      <section id="free-quote">
        <PrimeContactFormSection
          title="Let’s create an incredible software product together!"
          widthTitle="825px"
          formInfoClassName="formInfo__services"
          customSalesInfo
          customSalesInfoStepFour="4. Our custom software development experts will analyze your requirements and suggest the best ways to bring your idea to life."
        />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={CustomEnterpriseSoftwareDevFAQ} />
      </section>
    </MainLayout>
  )
}

export default CustomEnterpriseSoftwareDevelopment
