// extracted by mini-css-extract-plugin
export var customEnterpriseDevBenefitsSection = "H_gP";
export var customEnterpriseDevCallToActionSection = "H_gK";
export var customEnterpriseDevCaseSection = "H_gW";
export var customEnterpriseDevCostSection = "H_gQ";
export var customEnterpriseDevExperienceSection = "H_gV";
export var customEnterpriseDevExpertiseSection = "H_gT";
export var customEnterpriseDevIndustriesSection = "H_gJ";
export var customEnterpriseDevPracticesSection = "H_gR";
export var customEnterpriseDevPrimeSection = "H_gG";
export var customEnterpriseDevProcessSection = "H_gM";
export var customEnterpriseDevProjLogoSection = "H_gS";
export var customEnterpriseDevQuotesSection = "H_gX";
export var customEnterpriseDevServicesSection = "H_gH";
export var customEnterpriseDevSolutionSection = "H_gL";
export var customEnterpriseDevTechSection = "H_gN";